import React from 'react';
import classNames from 'classnames';
import Star, { starSize } from '../Star/Star';
import styles from './RatingFill.module.scss';

type ThemeName = 'havenly';

interface IRatingFillProps {
  compact?: boolean;
  theme?: ThemeName;
  fillColor?: string;
  strokeColor?: string;
  backgroundColor?: string;
  className?: string;
  starClassName?: string;
  rating: number;
  id: string;
  size?: starSize;
}

interface ThemeColor {
  fill?: string;
  stroke?: string;
  background?: string;
}

const THEME_COLORS: Record<string, ThemeColor> = {
  havenly: {
    fill: '#ffe657',
    stroke: 'rgba(0,0,0,0)',
    background: '#D8D8D8',
  }
};

const ratings = [1, 2, 3, 4, 5];

function convertToPartialFill(remainder: number) {
  if (remainder > 0.75) {
    return 100;
  }

  if (remainder < 0.25) {
    return 0;
  }

  return 50;
}

const RatingFill = ({
  compact,
  theme,
  fillColor,
  strokeColor,
  backgroundColor,
  className = '',
  starClassName = '',
  rating,
  id,
  size,
}: IRatingFillProps) => {
  const colors: ThemeColor = (theme && THEME_COLORS[theme]) || {};
  if (fillColor) colors.fill = fillColor;
  if (backgroundColor) colors.background = backgroundColor;
  if (strokeColor) colors.stroke = strokeColor;

  const fills = ratings.map((r) => {
    if (rating > r) {
      return 100;
    }

    const ratingDifference = r - rating;

    if (ratingDifference === 0) {
      return 100;
    }

    if (ratingDifference < 1) {
      return convertToPartialFill(rating % 1);
    }

    return 0;
  });

  return (
    <div
      className={classNames(
        className,
        'd-flex',
        'flex-row',
        styles['m-rating-fill'],
        (size && styles[size]) || ''
      )}
      data-test="rating-fill"
    >
      {fills.map((fill, index) => {
        const starId = `${id}-${index}`;

        return (
          <Star
            compact={compact}
            data-test="rating-fill__star"
            id={starId}
            className={classNames(
              starClassName,
              styles.star, {
                [styles.large]: size === 'large',
                [styles.small]: size === 'small'
              }
            )}
            key={starId}
            fill={fill}
            size={size}
            fillColor={colors.fill}
            strokeColor={colors.stroke}
            backgroundColor={colors.background}
          />
        );
      })}
    </div>
  );
};

export default RatingFill;
