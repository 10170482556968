import React from 'react';

import classNames from 'classnames';
import { useTheme } from '@components/theme';
import { IFeed, IInstagramFeeds } from '@lib/instagram/client';
import Feed from './Feed';
import styles from './InstagramFeed.module.scss';

export default function InstagramFeed({ feeds }: IInstagramFeeds) {
  const theme = useTheme();
  const titleClass = classNames(theme.styles.Header, styles.Title);

  return (
    <section className={styles.Container}>
      <header className={styles.HeaderContainer}>
        <h2 className={titleClass}>Follow us on Instagram</h2>
      </header>
      <div className={styles.FeedContainer}>
        {feeds.map((feed: IFeed) => (
          <Feed
            key={feed.id}
            caption={feed.caption}
            mediaType={feed.media_type}
            mediaUrl={feed.media_url}
            thumbnailUrl={feed.thumbnail_url}
          />
        ))}
      </div>
    </section>
  );
}
