/* eslint-disable camelcase */
import React, { useContext } from 'react';
import NextLink from 'next/link';
import classNames from 'classnames';
import { Link, RichText } from 'prismic-reactjs';
import { IPrismicText, IPrismicUrl } from '@lib/prismic/types';
import { asText } from '@lib/prismic/util/document';
import parseUrl from '@lib/prismic/util/parseUrl';
import { useTheme } from '@components/theme';
import If from '@components/shared/conditional/If/If';
import UserContext from '@context/User/user-context';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMinBreakpoint } from '@hooks/useMediaQuery/useMediaQuery';
import ResponsiveImage from '@components/shared/Image/ResponsiveImage/ResponsiveImage';
import styles from './FullBleedSliderHero.module.scss';
import Badge from './assets/badge.svg';
import BadgeMobile from './assets/badge-m.svg';

SwiperCore.use([Autoplay]);

interface IFullBleedSliderHero {
  hero_subtitle?: IPrismicText[];
  hero_description?: IPrismicText[];
  hero_eyebrow?: IPrismicText[];
  hero_cta_text?: IPrismicText[];
  hero_cta_link?: IPrismicUrl;
  hero_cta_logged_in_link?: IPrismicUrl;
  ctaOnClick?: (e: any) => void;
  className?: string;
  prefetchHeroCtaLink?: boolean;
  ctaDataTest?: string;
}

export default function FullBleedSliderHero({
  hero_subtitle,
  hero_description,
  hero_eyebrow,
  hero_cta_text,
  hero_cta_link,
  hero_cta_logged_in_link,
  ctaOnClick = () => {
  },
  className = '',
  prefetchHeroCtaLink = false,
}: IFullBleedSliderHero) {
  const theme = useTheme();
  const { user } = useContext(UserContext);
  const isMobile = !useMinBreakpoint('tablet');

  return (
    <section className={classNames(styles.FullBleedSliderHero, className)}>

      <div className={styles.AspectRatioBox}>
        <div className={styles.AspectRatioBoxInside}>
          <Swiper
            dir={`rtl`}
            className={styles.swiper}
            slidesPerView={'auto'}
            speed={4000}
            autoplay={{
              delay: 2000,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              reverseDirection: true
            }}
            loop
          >
            <SwiperSlide className={styles.slide}>
              <ResponsiveImage
                url={'https://static.havenly.com/home/hero/odette.jpg'}
                alt={'Designed for Odette'}
                className={styles.slideImage}
                sizes={[{
                  media: '(min-width: 1440px)',
                  width: 1920,
                  height: 1291,
                }, {
                  media: '(min-width: 1100px)',
                  width: 1250,
                  height: 841,
                }, {
                  media: '(min-width: 600px)',
                  width: 1009,
                  height: 841,
                }]}
                width={600}
                height={459}
                format={'jpeg'}
              />
              <div className={styles.slideOverlay} />
              <span className={styles.slideAuthor}>{'Designed for Odette'}</span>
            </SwiperSlide>
            <SwiperSlide className={styles.slide}>
              <ResponsiveImage
                url={'https://static.havenly.com/home/hero/blair.jpg'}
                alt={'Designed for Blair'}
                className={styles.slideImage}
                sizes={[{
                  media: '(min-width: 1440px)',
                  width: 1920,
                  height: 1291,
                }, {
                  media: '(min-width: 1100px)',
                  width: 1250,
                  height: 841,
                }, {
                  media: '(min-width: 600px)',
                  width: 1009,
                  height: 841,
                }]}
                width={600}
                height={459}
                format={'jpeg'}
              />
              <div className={styles.slideOverlay} />
              <span className={styles.slideAuthor}>{'Designed for Blair'}</span>
            </SwiperSlide>
            <SwiperSlide className={styles.slide}>
              <ResponsiveImage
                url={'https://static.havenly.com/home/hero/chris-shelby.jpg'}
                alt={'Designed for Chris & Shelby'}
                className={styles.slideImage}
                sizes={[{
                  media: '(min-width: 1440px)',
                  width: 1920,
                  height: 1291,
                }, {
                  media: '(min-width: 1100px)',
                  width: 1250,
                  height: 841,
                }, {
                  media: '(min-width: 600px)',
                  width: 1009,
                  height: 841,
                }]}
                width={600}
                height={459}
                format={'jpeg'}
              />
              <div className={styles.slideOverlay} />
              <span className={styles.slideAuthor}>{'Designed for Chris & Shelby'}</span>
            </SwiperSlide>
            <SwiperSlide className={styles.slide}>
              <ResponsiveImage
                url={'https://static.havenly.com/home/hero/ketlie.jpg'}
                alt={'Designed for Ketlie'}
                className={styles.slideImage}
                sizes={[{
                  media: '(min-width: 1440px)',
                  width: 1920,
                  height: 1291,
                }, {
                  media: '(min-width: 1100px)',
                  width: 1250,
                  height: 841,
                }, {
                  media: '(min-width: 600px)',
                  width: 1009,
                  height: 841,
                }]}
                width={600}
                height={459}
                format={'jpeg'}
              />
              <div className={styles.slideOverlay} />
              <span className={styles.slideAuthor}>{'Designed for Ketlie'}</span>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <div className={styles.MaxWidthContainer}>
        <div className={styles.ContentContainer}>
          <div className={styles.topBadge}>
            {isMobile ? (<BadgeMobile />) : (<Badge />)}
            <span>Interior Design Service</span>
          </div>

          <If condition={!!hero_eyebrow}>
            <div
              className={classNames(
                theme.styles.Overline,
                styles.Eyebrow
              )}
            >
              {asText(hero_eyebrow!)}
            </div>
          </If>

          <h1 className={classNames(styles.Title)}>
            {asText(hero_subtitle!)}
          </h1>

          <If condition={!!hero_description}>
            <div
              className={classNames(
                theme.styles.Body,
                styles.Description
              )}
            >
              {RichText.render(hero_description!)}
            </div>
          </If>

          <If condition={!!hero_cta_text}>
            <NextLink
              href={
                user && hero_cta_logged_in_link
                  ? parseUrl(Link.url(hero_cta_logged_in_link))
                  : parseUrl(Link.url(hero_cta_link))
              }
              prefetch={prefetchHeroCtaLink}
            >
              {/* eslint-disable */}
              <a
                className={classNames(theme.styles.Button,
                  theme.styles.Primary,
                  theme.styles.Medium,
                  styles.CTAButton)}
                role="button"
                onClick={ctaOnClick}
              >
                {/* @ts-ignore */}
                {asText(hero_cta_text)}
              </a>
              {/* eslint-enable */}
            </NextLink>

          </If>
        </div>
      </div>
    </section>
  );
}
