/* istanbul ignore file */
import React, { useState } from 'react';

// import analyticsTrack from '@lib/analytics/analyticsTrack';
import VideoModalContext, { IVideoModal } from '@context/Modal/video-modal-context';
import VideoModal from '@components/shared/modals/VideoModal/VideoModal';

const VideoModalProvider:React.FunctionComponent = ({ children }) => {
  const [
    modalState,
    setModalState,
  ] = useState<IVideoModal | null>(null);

  const closeModal = () => {
    setModalState(null);
  };

  return (
    <VideoModalContext.Provider
      value={{
        open: setModalState,
      }}
    >
      {modalState && (
        <VideoModal
          source={modalState.source}
          onClose={closeModal}
        />
      )}
      {children}
    </VideoModalContext.Provider>
  );
};

export default VideoModalProvider;
