export default function prepareAlternativesForAnalytics(results: any[]) {
  return results.map((product, index) => {
    return {
      designerRecommended: product.isDesignerRecommended || false,
      listPosition: index,
      price: product.retailPrice?.price,
      productName: product.title,
      salePrice: product.salePrice?.price,
      vendorName: product.vendor?.name,
      vendorVariantId: product.id,
    };
  });
}
