import React, { useRef, useState } from 'react';
import Item from './Item/Item';
import Handle from './Handle/Handle';

import classes from './ComparisonSlider.module.scss';

interface IItemOne {
  description: string;
}

interface IItemTwo {
  description: string;
}

interface IComparisonSlider {
  itemOne: IItemOne;
  itemTwo: IItemTwo;
  initialValue: string;
  inputLabel: string;
}

export default function ComparisonSlider({
  itemOne, itemTwo, initialValue = '50', inputLabel
} : IComparisonSlider) {
  const [currentValue, setCurrentValue] = useState(initialValue);
  const [isChanged, setIsChanged] = useState(false);

  // const itemOneDescription = itemOne.description;
  // const itemTwoDescription = itemTwo.description;

  const container = useRef<any>(null);

  const onInputSlider = (event: any) => {
    setCurrentValue(event.target.value.toString());

    if (!isChanged) {
      setIsChanged(true);

      const sliderChanged = new CustomEvent('comparisonSlider:changed', {
        bubbles: true
      });

      container.current.dispatchEvent(sliderChanged);
    }
  };

  return (
    <div className={classes.root} ref={container} style={{ ['--exposure' as any]: `${currentValue}%` }}>
      <Item targetEl={false} {...itemOne} />
      <Item {...itemTwo} targetEl />

      <Handle
        // itemOneDescription={itemOneDescription}
        // itemTwoDescription={itemTwoDescription}
        defaultValue={initialValue}
        currentValue={currentValue}
        onInput={onInputSlider}
        inputLabel={inputLabel}
      />
    </div>
  );
}
