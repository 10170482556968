import React from 'react';
import styles from './HappinessGuarantee.module.scss';

export default function HappinessGuarantee() {
  return (
    <div className={styles.Root}>
      <h2 className={styles.Title}>our happiness guarantee</h2>
      <p className={styles.Content}>
        {/* eslint-disable-next-line max-len */}
        If you’re not happy, we’re not happy. We know designing your home can be an intimidating experience, if you’re
        not happy with your design for whatever reason, let us know, and we’ll make it right.
      </p>
    </div>
  );
}
