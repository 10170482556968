import React from 'react';

import styles from './Item.module.scss';

interface IItem {
  content?: string;
  targetEl: boolean;
}

export default function Item({ content, targetEl = false } : IItem) {
  // eslint-disable-next-line max-len
  return (
    <div className={targetEl ? styles.comparisonSliderItemTarget : styles.comparisonSliderItem}>
      {content}
    </div>
  );
}

