/* istanbul ignore file */
import { useMinBreakpoint } from '@hooks/useMediaQuery/useMediaQuery';
import React, { RefAttributes } from 'react';

import styles from './AspectRatioBox.module.scss';

interface IAspectRatioBoxProps extends RefAttributes<any> {
  aspectRatio?: number;
  children: any;
  className?: string;
  maxBreakpoint?: string | number;
  maxPaddingTop?: number;
}

/**
 * stolen from https://css-tricks.com/aspect-ratio-boxes/
 */
const AspectRatioBox = React.forwardRef(({
  children,
  aspectRatio = 1,
  className = '',
  maxBreakpoint,
  maxPaddingTop
}: IAspectRatioBoxProps, ref) => {
  const aboveMaxWidth = maxBreakpoint ? useMinBreakpoint(maxBreakpoint) : false;

  return (
    <div
      className={ `${styles.AspectRatioBox} ${className}` }
      ref={ ref as React.RefObject<HTMLDivElement>}
      style={{ paddingTop: aboveMaxWidth ? maxPaddingTop : `calc(${aspectRatio} * 100%)` }}
    >
      <div className={ styles.AspectRatioBoxInside }>
        {children}
      </div>
    </div>
  );
});

export default AspectRatioBox;
