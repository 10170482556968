import React from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import Link from 'next/link';
import styles from './DesignHelp.module.scss';

SwiperCore.use([Autoplay]);

export default function DesignHelp() {
  const faqs = [
    {
      id: 1,
      url: 'https://havenly.com/in-person',
      title: 'In-Person Design Services',
    },
    {
      id: 2,
      url: 'https://havenly.com/interior-designers/new-york',
      title: 'New York Interior Designers',
    },
    {
      id: 3,
      url: 'https://havenly.com/exp/coastal-design-ideas ',
      title: 'Coastal Interior Design',
    },
    {
      id: 4,
      url: 'https://havenly.com/exp/bedroom-design-ideas',
      title: 'Bedroom Design Ideas',
    },
    {
      id: 5,
      url: 'https://havenly.com/blog/designers-moving-checklist',
      title: 'Moving Checklist',
    },
    {
      id: 6,
      url: 'https://havenly.com/budget',
      title: 'Budget Calculator',
    },
    {
      id: 7,
      url: 'https://havenly.com/exp/modern-design-ideas',
      title: 'Modern Design',
    },
    {
      id: 8,
      url: 'https://havenly.com/exp/bohemian-design-ideas',
      title: 'Bohemian Design',
    },
    {
      id: 9,
      url: 'https://havenly.com/exp/transitional-design-ideas',
      title: 'Transitional Design',
    },
    {
      id: 10,
      url: 'https://havenly.com/exp/midcentury-modern-design-ideas',
      title: 'Midcentury Modern Design',
    },
    {
      id: 11,
      url: 'https://havenly.com/exp/rustic-design-ideas',
      title: 'Rustic Design',
    },
    {
      id: 12,
      url: 'https://havenly.com/interior-design-style-quiz',
      title: 'Style Quiz',
    },
    {
      id: 13,
      url: 'https://havenly.com/design-awards',
      title: 'Design Awards 2023',
    },
    {
      id: 14,
      url: 'https://havenly.com/blog/interior-design-trends-2023',
      title: 'Design Trends',
    },
    {
      id: 15,
      url: 'https://havenly.com/blog/top-designer-recommended-styling-tips',
      title: 'Designer Tips',
    },
    {
      id: 16,
      url: 'https://havenly.com/blog/performance-fabric-sofa',
      title: 'Performance Fabrics',
    },
    {
      id: 17,
      url: 'https://havenly.com/shop/collection/pattern-play',
      title: 'Pattern Play',
    },
    {
      id: 18,
      url: 'https://havenly.com/shop/collection/accent-chairs',
      title: 'Accent Furniture',
    },
    {
      id: 19,
      url: 'https://apps.apple.com/us/app/havenly-interior-design/id1149153371',
      title: 'Havenly App',
    }
  ];

  const Sliders1 = faqs.map((faq) => {
    if (!faq.url.includes('blog')) {
      return (
        <Link key={faq.id} className={styles.FaqLink} href={faq.url} target={'_blank'}>
          {faq.title}
        </Link>
      );
    } else {
      return (
        <a key={faq.id} className={styles.FaqLink} href={faq.url} target={'_blank'}>
          {faq.title}
        </a>
      );
    }
  });

  return (
    <div className={styles.Root}>
      <h2 className={styles.Title}>Get design help today</h2>
      <div className={styles.ButtonsFade}>
        <div className={styles.ButtonsSlider}>
          <div className={styles.ButtonsSliderWrapper}>
            {Sliders1}
          </div>
        </div>
      </div>
    </div>
  );
}
