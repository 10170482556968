/* istanbul ignore file */

enum breakpoints {
  'standard-phone' = 375,
  'large-phone' = 440,
  'small-tablet' = 600,
  'medium-tablet' = 768,
  'tablet' = 800,
  'large-tablet' = 920,
  'small-desktop' = 1024,
  'desktop' = 1100,
  'medium-desktop' = 1280,
  'large-desktop' = 1400,
  'xlarge-desktop' = 1600,
  'desktop-nav' = 1050,
}

export type BreakPoints =
  'standard-phone' |
  'large-phone' |
  'small-tablet' |
  'medium-tablet' |
  'tablet' |
  'large-tablet' |
  'small-desktop' |
  'desktop' |
  'medium-desktop' |
  'large-desktop' |
  'xlarge-desktop' |
  'desktop-nav';

export default breakpoints;
