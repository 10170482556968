import React from 'react';
import styles from './StarIcon.module.scss';

interface IStarIconProps {
  compact?: boolean;
  id: string;
  fill: number;
  className: string;
  stroke: number;
  fillColor?: string;
  strokeColor?: string;
  backgroundColor?: string;
}

const StarIcon = ({
  compact,
  id,
  fill,
  className,
  stroke,
  fillColor = 'black',
  strokeColor = 'black',
  backgroundColor = 'white'
}: IStarIconProps) => {
  let _fillColor = fillColor;

  if (fillColor === 'gold') {
    _fillColor = '#ffb400';
  }
  return (
    <svg
      data-test="star-icon"
      className={`
        ${styles['a-star-icon']}
        ${className}
        ${compact ? styles['-compact'] : ''}
      `}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      viewBox="0 0 11 11"
      stroke={_fillColor}
      stopColor={_fillColor}
    >
      {fill}
      <defs>
        <linearGradient id={id}>
          <stop
            className={`
              ${styles[`a-star-icon__gradient`]}
            `}
            offset={`${fill}%`}
            stopColor={_fillColor}
            stroke={_fillColor}
          />
          <stop
            offset={`${fill}%`}
            stopColor={backgroundColor}
            stopOpacity="1"
          />
        </linearGradient>
      </defs>
      <path
        className={styles[`a-star-icon__path`]}
        d="M8.235 9.509l-.522-3.033 2.21-2.146-3.055-.442L5.5 1.126 4.132 3.888l-3.055.442 2.21 2.146-.522 3.033L5.5 8.077l2.735 1.432z"
        fill={`url(#${id})`}
        strokeWidth={stroke}
        style={{ stroke: strokeColor }}
      />
    </svg>
  );
};

export default StarIcon;
