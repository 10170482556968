import { useContext, useEffect } from 'react';
import UserContext from '@context/User/user-context';
import { ExperimentTitles } from '@models/Experiment';
import browser from '@utils/browser/browser';
import { assignUserToAnonymousExperiment } from '@lib/experiment';
import logger from '@lib/logger';

export default function useAssignToBranchJourneyBannerExperiment() {
  const {
    havenlyAnonymousId,
    experimentVariations,
    addExperimentVariations,
  } = useContext(UserContext);

  useEffect(() => {
    const isIOS = browser.isIOS();
    if (isIOS) {
      assignUserToAnonymousExperiment(
        havenlyAnonymousId,
        ExperimentTitles.BRANCH_JOURNEY_BANNER_TEST,
        experimentVariations,
      ).then(([assignedVariation]) => {
        if (assignedVariation) {
          addExperimentVariations(assignedVariation);
        }
      }).catch((error) => logger().error(error)); // eslint-disable-line no-console
    }
  }, [havenlyAnonymousId]);
}
