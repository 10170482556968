/* istanbul ignore file */

import { useEffect, useState } from 'react';
import breakpoints from '@constants/breakpoints';

/**
 * returns true when when display is larger than minWidth, false otherwise.
 * if minWidth is not specified, always returns false
 * https://github.com/vercel/next.js/discussions/14810
 */
export default function useMediaQuery(query?: number | string, defaultValue: boolean = false) {
  let mediaQuery: string;
  if (typeof query === 'string') {
    mediaQuery = query;
  } else {
    mediaQuery = `(min-width: ${query || 0}px)`;
  }

  const [targetReached, setTargetReached] = useState(defaultValue);

  useEffect(() => {
    let active = true;

    if (typeof window === 'undefined' || typeof window.matchMedia === 'undefined') {
      return undefined;
    }

    const media = window.matchMedia!(mediaQuery);
    const updateTarget = () => {
      if (active) {
        setTargetReached(media.matches);
      }
    };
    updateTarget();
    media.addListener(updateTarget);

    return () => {
      active = false;
      media.removeListener(updateTarget);
    };
  }, [query, defaultValue]);

  return targetReached;
}

/**
 * Hook that determines if the window width is smaller than the given breakpoint.
 * @param breakpoint The breakpoint. Can be an absolute number or
 *                   a named breakpoint size (e.g. 'large-phone')
 * @return State variable that is `true` if the window width is greater than
 *         or equal to the breakpoint.
 */
export function useMaxBreakpoint(breakpoint?: number | string) {
  const breakpointWidth = parseBreakpointWidth(breakpoint);
  return useMediaQuery(breakpoint && `(max-width: ${breakpointWidth}px)`);
}

/**
 * Hook that determines if the window width is greater than the given breakpoint.
 * @param breakpoint The breakpoint. Can be an absolute number or
 *                   a named breakpoint size (e.g. 'large-phone')
 * @return State variable that is `true` if the window width is less than the breakpoint.
 */
export function useMinBreakpoint(breakpoint?: number | string) {
  const breakpointWidth = parseBreakpointWidth(breakpoint);
  return useMediaQuery(breakpoint && `(min-width: ${breakpointWidth}px)`);
}

function parseBreakpointWidth(breakpoint?: number | string) {
  let breakpointWidth: breakpoints | number = 0;
  if (breakpoint) {
    if (typeof breakpoint === 'string') {
      breakpointWidth = breakpoints[breakpoint as keyof typeof breakpoints];
    } else {
      breakpointWidth = breakpoint;
    }
  }
  return breakpointWidth;
}
