import React from 'react';

interface IFeed {
  caption: string;
  mediaType: string;
  mediaUrl: string;
  thumbnailUrl?: string;
}

export default function Feed({ // eslint-disable-next-line camelcase
  caption, mediaType, mediaUrl, thumbnailUrl
}: IFeed) {
  const imageSrc = mediaType === 'VIDEO' ? thumbnailUrl : mediaUrl;

  return (
    <a href="https://www.instagram.com/thehavenly/" target="_blank">
      <img src={imageSrc} alt={caption} />
    </a>
  );
}
