import React from 'react';
import Modal from 'react-bootstrap/Modal';

import EmbeddedVideo from '@components/shared/EmbeddedVideo/EmbeddedVideo';
import CloseButton from '../shared/CloseButton/CloseButton';

import styles from './VideoModal.module.scss';

interface IVideoModalProps {
  onClose: () => void;
  source: string;
}

export default function VideoModal({
  onClose,
  source
}: IVideoModalProps) {
  return (
    <Modal
      show
      onClose={onClose}
      dialogClassName={ styles.ModalDialog}
      contentClassName={ styles.ModalContent }
      onHide={onClose}
      centered
    >
      <Modal.Header className={ styles.ModalHeader }>
        <CloseButton className={ styles.CloseButton } onClick={onClose} />
      </Modal.Header>
      <Modal.Body className={ styles.ModalBody }>
        <EmbeddedVideo title="How It Works Video" source={source} />
      </Modal.Body>
    </Modal>
  );
}
