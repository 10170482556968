import React from 'react';
import classNames from 'classnames';
import StarIcon from './Icon/StarIcon';
import styles from './Star.module.scss';

export type starSize = 'large' | 'small';

interface IStarProps {
  compact?: boolean;
  id: string;
  fill: number;
  size?: starSize;
  className?: string;
  fillColor?: string;
  strokeColor?: string;
  backgroundColor?: string;
}

const Star = ({
  compact,
  id,
  fill,
  size,
  className,
  fillColor,
  strokeColor,
  backgroundColor
}: IStarProps) => {
  const classes = classNames({
    [styles['a-star']]: true,
    [styles['-size-lg']]: size === 'large',
    [styles['-size-sm']]: size === 'small',
  });

  return (
    <div className={classes} data-test="star">
      <StarIcon
        compact={compact}
        stroke={size === 'large' ? 0.5 : 0.8}
        id={id}
        data-test="star__icon"
        className={`${className} ${styles['a-star__icon']}`}
        fill={fill}
        fillColor={ fillColor }
        strokeColor={ strokeColor }
        backgroundColor={ backgroundColor }
      />
    </div>
  );
};

export default Star;
