import Cookie from 'js-cookie';

export const OPEN_INSTALL_APP_BANNER_DISMISSED_COOKIE = 'open_install_app_banner_dismissed';

/**
 * sets a browser cookie indicating banner has been dismissed. Expires in 7 days.
 */
export function setOpenInstallAppBannerDismissed(): void {
  Cookie.set(
    OPEN_INSTALL_APP_BANNER_DISMISSED_COOKIE,
    '1',
    {
      expires: 7, // days
    },
  );
}

/**
 * returns true if banner has been dismissed in last 7 days, else false.
 */
export function isOpenInstallAppBannerDismissed(): boolean {
  return !!Cookie.get(OPEN_INSTALL_APP_BANNER_DISMISSED_COOKIE);
}
