import React from 'react';

import styles from './EllipsisAnimation.module.scss';

interface IEllipsisAnimationProps {
  children?: React.ReactNode;
  className?: string;
}

const EllipsisAnimation = ({ children, className }: IEllipsisAnimationProps) => (
  <span className={ className } data-test="ellipsis-animation">
    { children }
    <span className={ `${styles.First} ${styles.Dot}` } />
    <span className={ `${styles.Second} ${styles.Dot}` } />
    <span className={ `${styles.Third} ${styles.Dot}` } />
  </span>
);

export default EllipsisAnimation;
