import React from 'react';
import boardItemTypes from '@constants/boardItemTypes';

export interface IAddToBoardModal {
  saveItemId: number;
  saveItemImageUrl: string;
  saveItemType: boardItemTypes;
  sourceBoard?: {
    id: number;
    designer?: {
      id: number;
    };
    subStyles?: {
      name: string;
    }[];
  };
  source: string;
}

export interface IAddToBoardModalContext {
  open: (state: IAddToBoardModal) => void;
}

/* istanbul ignore next */
const AddToBoardModalContext = React.createContext<IAddToBoardModalContext>({
  open: () => null,
});

export default AddToBoardModalContext;
