import React from 'react';

export interface IExploreProductModal {
  hideSave?: boolean;
  isDesignerRecommended?: boolean;
  projectBoardCommission?: {
    boardId: number;
    userId: number;
  };
  sourceBoardId?: number;
  readOnly: boolean;
  initialVendorVariantId: number;
  source: string;
  analyticsListId?: string;
  isClosed?: boolean;
}

export interface IExploreProductModalContext {
  open: (state: IExploreProductModal) => void;
  isClosed: boolean;
  onTriggeredAddToCart: (value: boolean) => void;
  triggeredAddToCart: boolean;
  onDoneAddingToCart: (value: boolean) => void;
  isDoneAddingToCart: boolean;
}

/* istanbul ignore next */
const ExploreProductModalContext = React.createContext<IExploreProductModalContext>({
  open: () => null,
  isClosed: true,
  onTriggeredAddToCart: () => null,
  triggeredAddToCart: false,
  onDoneAddingToCart: () => null,
  isDoneAddingToCart: false
});

export default ExploreProductModalContext;
