import React from 'react';
import classnames from 'classnames';

import styles from './EmbeddedVideo.module.scss';

interface IEmbeddedVideoProps {
  className?: string;
  title: string;
  source: string;
}

export default function EmbeddedVideo({ className = '', title, source }: IEmbeddedVideoProps) {
  return (
    <div className={ classnames(styles.ResponsiveContainer, className) }>
      <iframe
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder="0"
        src={source}
        title={title}
        className={ styles.Video }
      />
    </div>
  );
}
