import React from 'react';
import ResponsiveImage from '@components/shared/Image/ResponsiveImage/ResponsiveImage';
import classNames from 'classnames';
import styles from './HowHavenlyWorks.module.scss';

export default function HowHavenlyWorks() {
  return (
    <section className={styles.HowHavenlyWorks}>
      <div className={`${styles.MainContainer}`}>
        <h3 className={styles.SectionTitle}>how Havenly works</h3>
        <div className={styles.Row}>
          <div className={styles.Step}>
            <div className={styles.StepNumber}>01</div>
          </div>
          <div className={styles.Content}>
            {/* eslint-disable-next-line max-len */}
            <h4 className={styles.Title}>Match with one of our talented designers and get inspired.</h4>
            {/* eslint-disable-next-line max-len */}
            <p className={styles.Description}>With 10 years of experience in all 50 states and 100,000+ designs, we’ll help you hone your style, get inspired, and realize your design dreams for your specific space.</p>
          </div>
          <div className={classNames(styles.Media, styles.MediaGridThree)}>
            <div className={styles.Image01}>
              <ResponsiveImage
                url={'https://static.havenly.com/home/how-it-works/hiw_match_01.jpg'}
                alt={''}
                className={''}
                sizes={[
                  {
                    media: '(min-width: 1400px)',
                    width: 235,
                    height: 327
                  },
                  {
                    media: '(min-width: 1100px)',
                    width: 190,
                    height: 261
                  },
                  {
                    media: '(min-width: 768px)',
                    width: 134,
                    height: 184
                  }
                ]}
                width={171}
                height={238}
                format={'jpeg'}
              />
            </div>
            <div className={styles.Image02}>
              <ResponsiveImage
                url={'https://static.havenly.com/home/how-it-works/hiw_match_02.jpg'}
                alt={''}
                className={''}
                sizes={[
                  {
                    media: '(min-width: 1400px)',
                    width: 238,
                    height: 158
                  },
                  {
                    media: '(min-width: 1100px)',
                    width: 188,
                    height: 125
                  },
                  {
                    media: '(min-width: 768px)',
                    width: 132,
                    height: 89
                  }
                ]}
                width={172}
                height={114}
                format={'jpeg'}
              />
            </div>
            <div className={styles.Image03}>
              <ResponsiveImage
                url={'https://static.havenly.com/home/how-it-works/hiw_match_03.jpg'}
                alt={''}
                className={''}
                sizes={[
                  {
                    media: '(min-width: 1400px)',
                    width: 238,
                    height: 165
                  },
                  {
                    media: '(min-width: 1100px)',
                    width: 188,
                    height: 131
                  },
                  {
                    media: '(min-width: 768px)',
                    width: 133,
                    height: 92
                  }
                ]}
                width={172}
                height={119}
                format={'jpeg'}
              />
            </div>
          </div>
        </div>

        <div className={styles.Row}>
          <div className={styles.Step}>
            <div className={styles.StepNumber}>02</div>
          </div>
          <div className={styles.Content}>
            {/* eslint-disable-next-line max-len */}
            <h4 className={styles.Title}>Collaborate 1:1 with your designer to create your perfect&nbsp;space.</h4>
            {/* eslint-disable-next-line max-len */}
            <p className={styles.Description}>Let your designer guide you to a design that meets your functional needs and suits your unique style. See it come to life with a 3D rendering of the design in your actual space.</p>
          </div>
          <div className={styles.Media}>
            <div className={styles.Image}>
              <ResponsiveImage
                url={'https://static.havenly.com/home/how-it-works/hiw_colaborate.jpg'}
                alt={''}
                className={''}
                sizes={[
                  {
                    media: '(min-width: 1400px)',
                    width: 476,
                    height: 327
                  },
                  {
                    media: '(min-width: 1100px)',
                    width: 376,
                    height: 258
                  },
                  {
                    media: '(min-width: 768px)',
                    width: 269,
                    height: 183
                  }
                ]}
                width={343}
                height={226}
                format={'jpeg'}
              />
            </div>
          </div>
        </div>

        <div className={styles.Row}>
          <div className={styles.Step}>
            <div className={styles.StepNumber}>03</div>
          </div>
          <div className={styles.Content}>
            {/* eslint-disable-next-line max-len */}
            <h4 className={styles.Title}>Bring your design home <br />with ease.</h4>
            {/* eslint-disable-next-line max-len */}
            <p className={styles.Description}>Our designers source from 100+ home brands so no two spaces are ever alike. Leverage Havenly’s proprietary platform with a simple consolidated checkout and never overpay with our price&nbsp;guarantee.</p>
          </div>
          <div className={classNames(styles.Media, styles.MediaGridTwo)}>
            <div className={styles.Image01}>
              <ResponsiveImage
                url={'https://static.havenly.com/home/how-it-works/hiw_bring_01.jpg'}
                alt={''}
                className={''}
                sizes={[
                  {
                    media: '(min-width: 1400px)',
                    width: 236,
                    height: 326
                  },
                  {
                    media: '(min-width: 1100px)',
                    width: 186,
                    height: 257
                  },
                  {
                    media: '(min-width: 768px)',
                    width: 131,
                    height: 182
                  }
                ]}
                width={175}
                height={232}
                format={'jpeg'}
              />
            </div>
            <div className={styles.Image02}>
              <ResponsiveImage
                url={'https://static.havenly.com/home/how-it-works/hiw_bring_02.jpg'}
                alt={''}
                className={''}
                sizes={[
                  {
                    media: '(min-width: 1400px)',
                    width: 235,
                    height: 326
                  },
                  {
                    media: '(min-width: 1100px)',
                    width: 188,
                    height: 257
                  },
                  {
                    media: '(min-width: 768px)',
                    width: 133,
                    height: 182
                  }
                ]}
                width={174}
                height={232}
                format={'jpeg'}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
