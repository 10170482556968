import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { PRISMIC_PREVIEW_COOKIE, repoName } from '@lib/prismic/prismic-configuration';

function getExitPreviewRoute(router: NextRouter) {
  const defaultPreviewExitUrl = '/api/exit-preview';
  const linkUrl = router.asPath
    ? `${defaultPreviewExitUrl}?currentUrl=${router.asPath}`
    : defaultPreviewExitUrl;
  return linkUrl;
}

const useUpdatePreview = (previewRef: string, documentId: string) => {
  const router = useRouter();
  const previewExitRoute = getExitPreviewRoute(router);

  useEffect(() => {
    const rawPreviewCookie = Cookies.get(PRISMIC_PREVIEW_COOKIE);
    const previewCookie = rawPreviewCookie
      ? JSON.parse(rawPreviewCookie)
      : null;

    const previewCookieObject = previewCookie
      ? previewCookie[`${repoName}.prismic.io`]
      : null;

    const previewCookieRef =
      previewCookieObject && previewCookieObject.preview
        ? previewCookieObject.preview
        : null;

    const pushToRouter = async (route: string) => {
      await router.push(route);
    };

    if (router.isPreview) {
      if (rawPreviewCookie && previewCookieRef) {
        if (previewRef !== previewCookieRef) {
          pushToRouter(`/api/preview?token=${previewCookieRef}&documentId=${documentId}`);
        }
      } else {
        pushToRouter(previewExitRoute);
      }
    } else if (rawPreviewCookie && previewCookieRef) {
      pushToRouter(`/api/preview?token=${previewCookieRef}&documentId=${documentId}`);
    }
    return undefined;
  }, []);
};

export default useUpdatePreview;
