import analyticsTrack from '@lib/analytics/analyticsTrack';
import logger from '@lib/logger';

const log = logger('defaultLinkTracking');

// Strips extra whitespace from link text
const cleanLinkText = (el: any) => el.textContent.replace(/^[\s]+|$[\s]+|\b [\s]+/gm, '');

// Returns section number of provided element, 0 value returns "Hero"
const getElementSectionInteger = (el: any) => {
  try {
    const section = el.closest('section');
    const sectionIndex = Array.from(section.parentNode.children).indexOf(section);

    return sectionIndex || 'Hero';
  } catch (error: any) {
    log.error(error);
    return -1;
  }
};

export default function defaultLinkTracking(
  event: string,
  el: any,
  linkText: string | null = null,
  href: string | null = null
) {
  try {
    analyticsTrack(event, {
      button_text: linkText !== null ? linkText : cleanLinkText(el),
      href: href !== null ? href : el.getAttribute('href'),
      location: getElementSectionInteger(el),
    });
  } catch (error: any) {
    log.error(error);
  }
}
