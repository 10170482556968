import React from 'react';

export interface IVideoModal {
  source: string;
}

export interface IVideoModalContext {
  open: (state: IVideoModal) => void;
}

/* istanbul ignore next */
const VideoModalContext = React.createContext<IVideoModalContext>({
  open: () => null,
});

export default VideoModalContext;
