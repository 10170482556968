import gql from 'graphql-tag';

export default gql`
  query GetSimilarProductsWithAlternates($id: ID!, $pageSize: Int!, $sourceBoardId: ID!) {
    similarVendorVariants(id: $id, pageSize: $pageSize) {
      items {
        id
        title
        images {
          id
          uri
        }
        currentPrice
        retailPrice {
          id
          price
        }
        salePrice {
          id
          price
        }
        vendor {
          id
          name
        }
        groupCount
      }
    }

    designerRecommendationsByVendorVariant(
      boardId: $sourceBoardId
      vendorVariantId: $id
    ) {
      id
      title
      images {
        id
        uri
      }
      retailPrice {
        id
        price
      }
      salePrice {
        id
        price
      }
      vendor {
        id
        name
      }
      groupCount
    }
  }
`;
