import React from 'react';

import styles from './Handle.module.scss';

interface IHandle {
  onInput: any;
  defaultValue: string;
  currentValue: string;
  inputLabel: string;
}

export default function Handle({
  onInput, defaultValue, inputLabel
} : IHandle) {
  return (
    <label className={styles.comparisonSlideLabel}>
      <span className={styles.visuallyHidden}>
        {inputLabel || `Select what percentage of image to show`}
      </span>
      <input
        onInput={onInput}
        type="range"
        min="0"
        max="100"
        defaultValue={defaultValue}
        className={styles.comparisonSliderInput}
      />
      <span className={styles.comparisonSliderHandle}>
        <span className={styles.comparisonSliderBefore}>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8,16L6.4,9.6l6.4-6.4" stroke="currentColor" strokeWidth="0.799573" strokeLinecap="round" />
          </svg>
          Before
        </span>
        <span className={styles.comparisonSliderAfter}>
          After
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.4,3.2l6.4,6.4L6.4,16" stroke="currentColor" strokeWidth="0.799573" strokeLinecap="round" />
          </svg>
        </span>
      </span>
    </label>
  );
}

