import React from 'react';
import PropTypes from 'prop-types';

import EllipsisAnimation from '../../EllipsisAnimation/EllipsisAnimation';
import styles from './ButtonPrimary.module.scss';

interface IButtonPrimaryProps {
  children: React.ReactNode;
  className?: string;
  compact?: boolean;
  disabled?: boolean;
  href?: string;
  isActive?: boolean;
  onClick?: (e: any) => void | Promise<void>;
  target?: string;
  title?: string;
}

const ButtonPrimary = ({
  children,
  className,
  compact,
  disabled,
  href,
  isActive,
  onClick,
  target,
  title,
}:IButtonPrimaryProps) => {
  const classNameList = [
    styles.ButtonPrimary,
    className,
  ];
  if (isActive) classNameList.push(styles.isActive);
  if (disabled) classNameList.push(styles.isDisabled);
  if (compact) classNameList.push(styles.isCompact);

  const classNames = classNameList.join(' ');

  if (href) {
    return (
      <a
        className={ classNames }
        data-test="button-primary"
        href={ href }
        onClick={ onClick }
        rel="noopener nofollow noreferrer"
        target={ target }
        title={ title }
      >
        {children}
        {isActive && (
          <EllipsisAnimation />
        )}
      </a>
    );
  }

  return (
    <button
      className={ classNames }
      data-test="button-primary"
      disabled={ disabled }
      onClick={ onClick }
      title={ title }
      type="button"
    >
      {children}
      {isActive && (
        <EllipsisAnimation />
      )}
    </button>
  );
};

ButtonPrimary.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  target: PropTypes.string,
  title: PropTypes.string,
};

export default ButtonPrimary;
