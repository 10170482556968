import React, { useContext, useState } from 'react';
import LoginRegisterModalContext, { ILoginRegisterModal } from '@context/Modal/login-register-modal-context';
import UserContext from '@context/User/user-context';
import dynamic from 'next/dynamic';

const LoginRegisterModal = dynamic(
  () => import('@components/shared/modals/LoginRegisterModal/LoginRegisterModal'),
  { ssr: false }
);

const DEFAULT_MODAL_STATE:Partial<ILoginRegisterModal> = {
  closable: true,
};

const LoginRegisterModalProvider:React.FunctionComponent = ({ children }) => {
  const [
    loginRegisterModalState,
    setLoginRegisterModalState,
  ] = useState<ILoginRegisterModal | null>(null);
  const { login } = useContext(UserContext);

  const customerModalState = {
    open: (opts: ILoginRegisterModal) => {
      setLoginRegisterModalState({
        ...DEFAULT_MODAL_STATE,
        ...opts
      });
    }
  };

  return (
    <LoginRegisterModalContext.Provider
      value={customerModalState}
    >
      {loginRegisterModalState && (
        <LoginRegisterModal
          {...loginRegisterModalState}
          onClose={() => {
            if (!loginRegisterModalState.closable) {
              return;
            }

            setLoginRegisterModalState(null);
            if (loginRegisterModalState.onClose) {
              loginRegisterModalState.onClose();
            }
          }}
          onLoginSuccess={ async () => {
            const user = await login();
            setLoginRegisterModalState(null);
            if (user) {
              loginRegisterModalState.onLoginSuccess(user);
            }
          }}
          onRegisterSuccess={ async () => {
            const user = await login();
            setLoginRegisterModalState(null);
            if (user) {
              loginRegisterModalState.onRegisterSuccess(user);
            }
          }}
        />
      )}
      {children}
    </LoginRegisterModalContext.Provider>
  );
};

export default LoginRegisterModalProvider;
