import * as Prismic from '@prismicio/client';
import Link from 'next/link';
import React from 'react';
import logger from '@lib/logger';
import env from '@lib/envs/env';

import { MERCH_GROUP_URL_PREFIX } from '@components/shared/shop/model/MerchGroup';
import { MERCH_GROUP_CATEGORY_URL_PREFIX } from '@components/shared/shop/model/MerchGroupCategory';
import smConfig from './sm.json';

const log = logger('prismic-configuration');

if (!smConfig.apiEndpoint) {
  log.warn('Looks like Slice Machine hasn\'t been bootstraped already.\nCheck the `Getting Started` section of the README file :)');
}

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
export const accessToken = env.PRISMIC_CUSTOMER_UI_ACCESS_TOKEN;

export const PRISMIC_PREVIEW_COOKIE = Prismic.cookie.preview;

export const { repoName, apiEndpoint } = smConfig;

/* eslint-disable quote-props */
// Mapping from document type to the page URL.  The document type is the type "API ID"
const DOC_TYPE_LINKS: Record<string, string> = {
  'page': '/{uid}',
  'home-page': '/',
  'shop_landing_page': '/shop',
  'merch_group_category': `${MERCH_GROUP_CATEGORY_URL_PREFIX}/{uid}`,
  'merch_group_page': `${MERCH_GROUP_URL_PREFIX}/{uid}`,
  'reviews_page': '/reviews',
  'interior-designer-home-page': '/interior-designers',
  'interior_designers_page': '/interior-designers/{uid}',
  'pricing_page': '/pricing',
  'financing': '/financing',
};
/* eslint-enable quote-props */

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc: any) => {
  const link = DOC_TYPE_LINKS[doc.type];
  if (link) {
    return link.replaceAll('{uid}', doc.uid);
  }

  return '/';
};

export const customLink = (
  type: any,
  element: any,
  content: any,
  children: any,
  index: any
) => (
  <Link
    key={index}
    href={linkResolver(element.data)}
    as={linkResolver(element.data)}
  >
    {/* eslint-disable jsx-a11y/anchor-is-valid */}
    <a>{content}</a>
  </Link>
);

export const Client = (_?: any, options = {}) => (
  Prismic.createClient(apiEndpoint, { ...options })
);
